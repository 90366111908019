import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';

import useHasMounted from 'context/useHasMounted';

const Wrap = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	opacity: ${p => (p.$hasMounted ? 1 : 0)};
	transition: opacity 0.5s ease-in-out;
	border-radius: ${p => p.theme.utils.borderRadius};

	${p =>
		p.thumb &&
		css`
			margin: 0 auto;
			> img {
				margin: auto;
				${p =>
					p.theme.media.large(css`
						width: 300px;
						height: 300px;
					`)}
			}
		`};
`;

const PlaceholderImg = styled.img`
	display: ${p => (p.$hide ? 'none' : 'block')};
	opacity: ${p => (p.$isLoading ? '1' : '0')};
	transition: opacity 0.5s ease-in-out;
	object-fit: contain;
	margin: auto;
	border-radius: ${p => p.theme.utils.borderRadius};
	${p =>
		p.$fill === 'true' &&
		css`
			object-fit: cover !important;
		`};
`;

const MainImg = styled.img`
	opacity: ${p => (p.$isLoaded ? 1 : 0)};
	transition: opacity 0.5s ease-in-out;
	border-radius: ${p => p.theme.utils.borderRadius};
	height: auto;
	display: block;
	margin: auto;
	${p =>
		p.$fill === 'true' &&
		css`
			width: 100%;
			margin: 0 auto;
		`};
`;

export default function LazyImage({
	sizes = [
		{ w: 1200, h: 750 },
		{ w: 768, h: 480 },
	],
	width = 768,
	height = 480,
	alt = '',
	ratio = 'none',
	...props
}) {
	const [isLoading, setIsLoading] = useState(true);
	const [isLoaded, setIsLoaded] = useState(false);
	const [hidePlaceholder, setHidePlaceholder] = useState(false);
	const [view, setView] = useState('');
	const placeholderRef = useRef(null);

	const hasMounted = useHasMounted();

	// Calculate width and height based on aspect ratio
	const calculated = calculateSizes(width, height, sizes, ratio);

	// Shorten the variable for the source

	const src = props?.file?.url;

	// Define params (format, quality, radius and fit)
	const imageParams = createImageParams(props?.fit, ratio);

	// Create srcSet-string from array of sizes
	const srcSet = createSrcSet(calculated?.sizes, src, imageParams);

	// Define fill
	const fill = !!calculated?.tooSmall
		? 'false'
		: !!['16:10', '1:1'].includes(ratio) || props?.fit === 'fill'
		? 'true'
		: 'false';

	// Load src when component is in view
	useEffect(() => {
		if (!src || props?.load === 'instant') return;

		// Initiating Intersection Observer
		const observer = new IntersectionObserver(entries => {
			// Set actual image source && unobserve when intersecting
			if (entries[0].isIntersecting) {
				setView(`${src}${imageParams}&w=${width}&h=${height}`);
				if (observer && placeholderRef?.current)
					observer?.unobserve(placeholderRef?.current);
			}
		});

		// observe for an placeholder image
		if (placeholderRef && placeholderRef.current) {
			observer?.observe(placeholderRef.current);
		}
		//eslint-disable-next-line
	}, [src]);

	// Load instantly
	useEffect(() => {
		if (!src || props?.load !== 'instant') return;
		setView(`${src}${imageParams}&w=${width}&h=${height}`);
		//eslint-disable-next-line
	}, [props?.load]);

	// If no url then return nothing
	if (!src) return <></>;

	// Svg file or not media in contentful
	if (
		(typeof src === 'string' && src?.endsWith('.svg')) ||
		(typeof src === 'string' && !src?.includes('images.ctfassets.net'))
	) {
		// Define classes
		let className = 'lazy-image__main';
		if (src?.endsWith('.svg')) className += ' lazy-image__main--svg';
		if (!src?.includes('images.ctfassets.net'))
			className += ' lazy-image__main--not-contentful';

		return (
			<Wrap className="lazy-image" $hasMounted={hasMounted} {...props}>
				<img
					src={src}
					alt={alt || props?.description || props?.title}
					className={className}
					{...props}
				/>
			</Wrap>
		);
	}

	// If not svg
	return (
		<>
			<Wrap className="lazy-image" $hasMounted={hasMounted} {...props}>
				<PlaceholderImg
					ref={placeholderRef}
					$isLoading={isLoading}
					$hide={hidePlaceholder}
					{...props}
					$fill={fill}
					src={`${src}${imageParams}&w=20&h=20`}
					width="100%"
					height="100%"
					className="lazy-image__placeholder"
					aria-hidden={true}
					style={{
						position: 'absolute',
						top: '0',
						left: '0',
						width: '100%',
						height: '100%',
					}}
					alt=""
				/>

				<MainImg
					src={view} // Gets src only when placeholder intersecting
					srcSet={view && srcSet}
					alt={alt || props?.description || props?.title}
					$isLoaded={isLoaded}
					className="lazy-image__main"
					onLoad={() => {
						if (!view) return;
						setIsLoading(false);
						setIsLoaded(true);
						setTimeout(() => {
							setHidePlaceholder(true);
						}, 700);
					}}
					width={width}
					height={height}
					data-ratio={ratio}
					$fill={fill}
					{...props}
				/>
			</Wrap>

			{isLoaded && <ImageTextOverlay {...props} />}
		</>
	);
}

/**
 * Creates a srcSet string from an array of sizes
 * @param {Array} sizes - Array of sizes
 * @param {String} src - The source url
 * @param {String} params - The image parameters
 * @returns {String} - The srcSet string
 */
export function createSrcSet(sizes, src, params) {
	if (!sizes?.length > 0 || !src) return '';

	// Check that sizes is an array of objects and that they either have a width or height
	if (
		!sizes?.every(
			size =>
				typeof size === 'object' &&
				(size?.w || size?.h) &&
				typeof size?.w === 'number' &&
				typeof size?.h === 'number'
		)
	) {
		return '';
	}

	// Returns srcSet string from array of sizes
	return sizes
		.map(size => `${src}${params}&h=${size?.h}&w=${size?.w} ${size?.w}w`)
		.join(', ');
}

/**
 * Creates a string of image parameters
 * @param {String} fit - The fit parameter (fill, pad, scale, crop, thumb)
 * @param {String} format - The format parameter (webp, jpg, png)
 * @param {Number} quality - The quality parameter (0-100)
 * @param {Number} radius - The radius parameter (0-100)
 * @returns {String} - The image parameters
 */
export function createImageParams(
	fit,
	ratio,
	format = 'webp',
	quality = 70,
	radius = 5
) {
	let imageParams = `?fm=${format}&q=${quality}&r=${radius}`;

	// Set fit parameter when aspect ratio is 16:10 or 1:1
	if (ratio && ['16:10', '1:1'].includes(ratio)) {
		return (imageParams += '&fit=fill');
	}

	if (fit && ['fill', 'pad', 'scale', 'crop', 'thumb'].includes(fit)) {
		return (imageParams += `&fit=${fit}`);
	}

	return imageParams;
}

/**
 * Calculates the width and height based on aspect ratio, and sets sizes
 * @param {Number} originalWidth - The original width of the image
 * @param {Number} originalHeight - The original height of the image
 * @param {Array} originalSizes - The original sizes of the image
 * @param {String} ratio - The aspect ratio of the image
 * @returns {Object} - The width, height and sizes
 */
function calculateSizes(originalWidth, originalHeight, originalSizes, ratio) {
	// Define width based on original width, but not larger than 2600px
	let width = Math.min(originalWidth, 2600) || 768;

	// Define height based on original height, but not larger than 1562px
	let height = Math.min(originalHeight, 1562) || 768;

	// Define sizes based on original sizes
	let sizes = originalSizes || [{ w: width, h: height }];

	// Function to calculate height based on aspect ratio
	const calculateHeight = (ratioWidth, ratioHeight) =>
		(width / ratioWidth) * ratioHeight;

	// Set height based on aspect ratio
	if (ratio === '16:10') {
		height = calculateHeight(16, 10);
	} else if (ratio === '1:1') {
		height = calculateHeight(1, 1);
	} else if (ratio === 'none') {
		height = originalHeight;
	}

	// Set sizes based on aspect ratio
	sizes = sizes.map(size => ({
		w: Math.round(size?.w),
		h:
			ratio === 'none'
				? Math.round(size?.h)
				: Math.round((size?.w / width) * height),
	}));

	// Find the largest width-size
	const largest = sizes.reduce((prev, current) =>
		prev?.w > current?.w ? prev : current
	);

	// If width is smaller than the largest width-size, or the largest width-size is larger than 2500px, then set sizes to the original width and height
	if (width < largest?.w || largest?.w > 2500) {
		sizes = [{ w: Math.round(width), h: Math.round(height) }];
	}

	// Add two more sizes if only one size
	if (sizes?.length === 1) {
		sizes.push(
			{ w: Math.round(width * 0.5), h: Math.round(height * 0.5) }, // Smaller size
			{ w: Math.round(width * 1.5), h: Math.round(height * 1.5) } // Bigger size
		);
	}

	return { width, height, sizes, tooSmall: width < 768 };
}

const Overlay = styled.div`
	padding: 30% 10px 10px 10px;
	text-align: center;
	opacity: ${p => (p.$hasCaption && 1) || 0};
	transition: opacity 350ms ease;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	z-index: 10;
	color: ${p => p.theme.colors.grey200} !important;
	> div {
		color: ${p => p.theme.colors.grey200} !important;
	}
	${p =>
		p.theme.media.large(css`
			padding: 35% 15px 15px 15px;
		`)};
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
		transition: all 0.35s ease;
		border-radius: ${p => p.theme.utils.borderRadius};
		background-image: linear-gradient(
			to bottom,
			rgba(40, 41, 44, 0),
			rgba(0, 0, 0, 0.8)
		);
	}
	&.no-caption:not(.clickable),
	&.hide:not(.clickable) {
		display: none;
	}
`;

const Caption = styled.div`
	max-width: calc(8 / 12 * 100%);
	margin: ${p => (p.placement === 'below' && '10px auto 0') || '0 auto'};
	display: ${p => (p.placement === 'below' && 'block') || 'inline-block'};
	width: 100%;
	position: relative;
	z-index: 2;
	font-size: 13px;
	line-height: 21px;
	text-align: center;
	font-style: italic;
	font-weight: 400;
	${p =>
		p.theme.media.large(css`
			font-size: 15px;
			line-height: 23px;
		`)};

	${p =>
		p.theme.media.smallOnly(css`
			flex-direction: column;
			max-width: 100%;
		`)}

	svg {
		z-index: 2;
		position: relative;
		margin-right: 10px;
		width: 21px;
		height: 23px;
		vertical-align: middle !important;
		${p =>
			p.theme.media.XSmallOnly(css`
				width: 17px;
				height: 19px;
			`)}
	}
	span:last-of-type:not(:first-of-type) {
		margin-left: 5px;
	}

	.text {
		color: ${p => p.theme.colors.black};
	}
	.link {
		text-decoration: underline;
	}
`;

export function ImageTextOverlay({ count, caption, text, ...props }) {
	const placement =
		props?.captionplacement || props?.settings?.captionplacement;

	if (!(caption && placement) && props?.clickable === 'false') return <></>;

	// If caption is below and not clickable
	if (placement === 'below') {
		return (
			<Caption className="caption" placement="below">
				<span className="text">{caption}</span>
				{props?.clickable === 'true' && (
					<>
						{'. '}
						<span className="link">
							{(count === 1 &&
								'Se større versjon av bildet' + text) ||
								'Se alle bilder' + text}
						</span>
					</>
				)}
			</Caption>
		);
	}

	// Determine classes based on caption and placement
	let classes = 'image-overlay';
	if (!!caption) classes += ' has-caption';
	if (!caption) classes += ' no-caption';
	if (props?.clickable === 'true') classes += ' clickable';
	if (placement) classes += ` ${placement}`;

	return (
		<Overlay className={classes} $hasCaption={!!caption}>
			{(caption && placement !== 'hide' && (
				<Caption className="caption">
					<span>{caption}</span>
				</Caption>
			)) ||
				(props?.clickable === 'true' && (
					<Caption className="caption">
						<FontAwesomeIcon
							icon={faImages}
							size="sm"
							width="21"
							height="23"
						/>
						<span>
							{(count === 1 &&
								'Se større versjon av bildet' + text) ||
								'Se alle bilder' + text}
						</span>
					</Caption>
				))}
		</Overlay>
	);
}

/**
 * Gets the smallest image width from an array of objects
 * @param {Array} array - The array of objects
 * @returns {Object} - The object with the smallest image width
 */
export function getSmallestImage(array) {
	if (!array || array?.length === 0) return null;

	const smallestImageObj = array?.reduce((smallest, obj) => {
		return !smallest || obj?.image?.width < smallest?.image?.width
			? obj
			: smallest;
	}, null);

	return smallestImageObj
		? {
				width: smallestImageObj?.image?.width,
				height: smallestImageObj?.image?.height,
		  }
		: null;
}
