import React from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { getPageType } from 'libs/content';
import Header from 'components/header/Header';
import Footer from 'components/footer/footer';

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;

	${p =>
		p.$transparentheader !== 'true' &&
		css`
			padding-top: 100px;
			${p =>
				p.theme.media.smallOnly(css`
					padding-top: 80px;
				`)}
		`} {
	}

	/** Since we can't pass page-props to Ninetailed-components we need this extra style for Topbanner */
	${p =>
		p.$transparentheader === 'true' &&
		css`
			.ContentfulKomponentToppbanner
				div:not(.next-component-float)
				.component__topbanner--bg {
				${p =>
					p.theme.media.large(css`
						padding-top: ${p =>
							`calc(${p.theme.spacing.desktop.large} + ${p.theme.spacing.desktop.small} + 100px)`};
					`)}
				${p =>
					p.theme.media.mediumOnly(css`
						padding-top: ${p =>
							`calc(${p.theme.spacing.tablet.large} + ${p.theme.spacing.tablet.small} + 100px)`};
					`)}
			${p =>
					p.theme.media.smallOnly(css`
						padding-top: ${p =>
							`calc(${p.theme.spacing.mobile.large} + 60px)`};
					`)}
			}
		`}

	@media print {
		padding-top: 0;
	}

	section {
		position: relative;
		// clearfix
		&:after {
			clear: both;
			content: '';
			display: table;
		}
	}

	footer {
		margin-top: auto;
	}
`;

const Main = styled.div`
	padding-top: 0;
	padding-bottom: 0;
	position: relative;
	flex-grow: 1;
	z-index: 3;
	${p =>
		p.theme.media.medium(css`
			padding-top: 0;
		`)}
	> div {
		width: 100%;
	}
`;

export default function Layout({
	location = {},
	pagesettings = {},
	secondaryMenu,
	style,
	children,
}) {
	// Get the navigation data and parse it
	const navData = useStaticQuery(navigationQuery);

	const isFrontPage = location?.pathname === '/';

	return (
		<Wrapper
			id="wrapper"
			data-cy={`${(isFrontPage && 'frontpage') || 'page'}`}
			$transparentheader={pagesettings?.transparentheader}
			$pageType={getPageType()}>
			<Header
				navData={navData?.nav?.nodes || []}
				location={location}
				secondaryMenu={secondaryMenu}
				{...pagesettings}
			/>

			<Main id="main" style={style} data-cy="main">
				{children}
			</Main>

			<Footer location={location} />
		</Wrapper>
	);
}

/**
 * Get the navigation items
 */
const navigationQuery = graphql`
	query navigation {
		nav: allContentfulNavigasjonLenke(sort: { order: ASC }) {
			nodes {
				id
				title
				description
				order
				icon
				segment
				linkParent {
					id
					link: relation {
						...RelationQueries
					}
				}
				link: relation {
					...RelationQueries
				}
				sibling {
					...RelationQueries
				}
			}
		}
	}
	fragment RelationQueries on ContentfulEntry {
		... on ContentfulSideNormal {
			slug
		}
		... on ContentfulStrukturRedirect {
			slug: redirectTil
		}
		... on ContentfulNavigasjonLenke {
			relation {
				... on ContentfulSideNormal {
					slug
				}
			}
		}
	}
`;
