import React, { useEffect, useRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapMarker from 'images/icons/map-marker.svg';
import mapMarkerSmall from 'images/icons/map-marker-small.svg';

mapboxgl.accessToken =
	'pk.eyJ1IjoibnRlLWFzIiwiYSI6ImNrcTI0amZsdTBmMDMyd3BmdTh3eDN6bTcifQ.nxgd5HQICkKnQp-OREqkPQ'; // NTE´s Mapbox Account

//#region Styling
const Wrapper = styled.div`
	height: 500px;
	overflow: hidden;
	position: relative;
	${p =>
		p.theme.media.smallOnly(css`
			height: 350px;
		`)}
`;

const MapContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	/* ${p =>
		p.theme.media.large(css`
			width: calc(100% - 17px);
		`)} */
	${p =>
		p.theme.media.medium(css`
			border-radius: ${p => p.theme.utils.borderRadius};
		`)}
	.marker-large {
		width: 35px;
		height: 35px;
		cursor: pointer;
		background-image: url('${mapMarker}');
		background-repeat: no-repeat;
		background-size: 35px 35px;
	}
	.marker-small {
		width: 25px;
		height: 25px;
		cursor: pointer;
		background-image: url('${mapMarkerSmall}');
		background-repeat: no-repeat;
		background-size: 25px 25px;
	}
	.mapboxgl-popup {
		max-width: 250px !important;
		${p =>
			p.theme.media.medium(css`
				max-width: 400px !important;
			`)}
		h3 {
			font-family: ${p => p.theme.typography.body.fontFamily};
			margin: 0;
			font-size: 18px;
			line-height: 24px;
			${p =>
				p.theme.media.medium(css`
					font-size: 20px;
					line-height: 26px;
				`)}
		}
		button:not(.mapboxgl-popup-close-button) {
			color: ${p => p.theme.colors.blue600};
			background: transparent;
			padding: 0;
			font-size: 14px;
			line-height: 22px;
			text-decoration: underline;
			&:hover {
				background-color: transparent;
				color: ${p => p.theme.colors.blue800};
			}
		}
		p {
			font-family: ${p => p.theme.typography.body.fontFamily};
			font-size: 14px;
			line-height: 22px;
			margin: 0;
		}
		ul {
			padding-left: 15px;
			text-align: left;
			margin: 0;
			li {
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	.mapboxgl-popup-content {
		text-align: left;
		padding: 15px;
		background: white;
		box-shadow: ${p => p.theme.utils.boxShadowHard};
		border-radius: ${p => p.theme.utils.borderRadius};
		${p =>
			p.theme.media.medium(css`
				padding: 20px;
			`)}
		a {
			color: ${p => p.theme.colors.blue600};
			outline: none;
		}
	}
	.mapboxgl-popup-close-button {
		color: ${p => p.theme.colors.black};
		outline: none;
		${p =>
			p.theme.media.medium(css`
				font-size: 22px;
				right: 5px;
				top: 5px;
			`)}
		&:hover {
			background-color: transparent;
			color: ${p => p.theme.colors.blue600};
		}
	}
`;

/**
 * Replaces tags in the tooltip template with the corresponding data from the marker object.
 * @param {Object} data - Marker object
 * @param {string} template - HTML template for the tooltip content
 * @param {Array} tags - Array of tags to be replaced in the tooltip template
 * @returns {string} HTML string with replaced tags
 **/
function getTooltipMarkup(data, template, tags) {
	let markupString = template;

	if (tags?.length > 0) {
		tags.forEach(tag => {
			if (
				tag === 'text' &&
				typeof data.tooltipContent[tag] === 'string'
			) {
				markupString = markupString.replaceAll(
					`{${tag}}`,
					`<p>${data.tooltipContent[tag] || ''}</p>`
				);
				return;
			}

			if (tag === 'text' && Array.isArray(data.tooltipContent[tag])) {
				let listMarkup = '<ul>';
				data.tooltipContent[tag].forEach(listItem => {
					listMarkup += `<li>${listItem}</li>`;
				});
				listMarkup += '</ul>';
				markupString = markupString.replace(`{${tag}}`, listMarkup);
				return;
			}

			markupString = markupString.replaceAll(
				`{${tag}}`,
				data.tooltipContent[tag] || ''
			);
			return;
		});

		return markupString;
	}
	return template.replace('{title}', data.tooltipContent.title);
}

/**
 * Map component that displays markers on a map.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.tooltips - Whether tooltips should be displayed on markers
 * @param {Function} props.onMarkerClick - Function to be called when a marker is clicked
 * @param {Array} props.markers - Array of marker objects to be displayed on the map
 * @param {string} props.tooltipTemplate - HTML template for the tooltip content
 * @param {Array} props.tooltipTemplateTags - Array of tags to be replaced in the tooltip template
 * @param {Array} props.center - Array of two numbers representing the initial center of the map [longitude, latitude]
 * @param {string} props.mapboxStyle - Mapbox style URL
 * @param {number} props.zoom - Initial zoom level of the map
 * @returns {JSX.Element} Map component
 */
const Map = forwardRef(
	(
		{
			tooltips = true,
			onMarkerClick,
			markers = [],
			tooltipTemplate = '<h3>{title}</h3>',
			tooltipTemplateTags = ['title'],
			center = [11.3028273, 63.8844802],
			mapboxStyle = 'mapbox://styles/mapbox/light-v11',
			zoom = 7,
			style,
			className,
		},
		ref
	) => {
		const mapContainerRef = useRef(null);

		useEffect(() => {
			const map = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: mapboxStyle,
				center,
				zoom,
			});

			if (!!ref) {
				ref.current = map;
			}

			if (!markers?.length > 0) return;

			//add markers to map
			markers?.forEach(marker => {
				// create a HTML element for each feature
				if (!marker.position) return;

				const el = document.createElement('div');
				el.className = marker?.markerClass || 'marker-small';
				if (onMarkerClick) {
					el.addEventListener('click', () => {
						onMarkerClick(marker);
					});
				}

				// make a marker for each feature and add to the map
				const point = new mapboxgl.Marker(el).setLngLat(
					marker.position
				);

				// Set tooltip offset based on marker position
				let offset = [0, -25];
				if (marker?.position?.lat > 67) offset = [0, 15];

				if (
					tooltips &&
					tooltipTemplateTags?.length > 0 &&
					marker?.tooltipContent?.title
				) {
					point.setPopup(
						new mapboxgl.Popup({
							offset,
							className: marker.tooltipClass || 'small-tooltip',
						}).setHTML(
							getTooltipMarkup(
								marker,
								tooltipTemplate,
								tooltipTemplateTags
							)
						)
					);
				}
				point.addTo(map);
			});

			// Center map around markers
			const bounds = new mapboxgl.LngLatBounds();
			markers.forEach(marker => {
				bounds.extend(marker.position);
			});
			map.fitBounds(bounds, { padding: 100 });

			// clean up on unmount
			return () => {
				map.remove();
			};

			//eslint-disable-next-line
		}, []);

		return (
			<Wrapper style={style} className={className}>
				<MapContainer ref={mapContainerRef} data-cy="map" />
			</Wrapper>
		);
	}
);

export default Map;
