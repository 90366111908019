import { css } from 'styled-components';
import { library, config, dom } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCaretRight as faCaretRightLight } from '@fortawesome/pro-light-svg-icons/faCaretRight';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { darken } from 'polished';

// Enable fontAwesome pseudo elements
config.searchPseudoElements = true;

export default function ListStyling() {
	// Add fontAwesome icon to library and watch DOM
	library.add(faCaretRight, faCaretRightLight);
	dom.watch();

	return css`
		ul:not(.tags):not(.job-vacancies),
		ol {
			font-size: 17px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.94;
			letter-spacing: normal;
			color: ${p => p.theme.colors.grey900};
			margin-left: 0;
			margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
			margin-top: 0;
			padding: ${p =>
				((p.$checkicon === 'true' || p.$xmarkicon === 'true') &&
					`0 0 ${p.theme.spacing.desktop.xsmall} ${p.theme.spacing.desktop.small}`) ||
				'0 0 1px 40px !important'};
			li {
				padding-left: 0;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				position: relative;
				line-height: 29px;
				&:last-of-type {
					margin-bottom: 0 !important;
				}
				> b {
					display: block;
				}
				svg {
					position: absolute;
					width: 25px;
					height: 20px;
					left: -${p => p.theme.spacing.tablet.medium};
					top: 5px;
					color: ${p => p.theme.colors.black};
				}

				${p =>
					p.theme.media.smallOnly(css`
						margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
					`)}
				figure {
					margin: ${p =>
						`${p.theme.spacing.desktop.small} 0`} !important;
				}
			}
		}
		ul:not(.tags):not(.job-vacancies) {
			${p =>
				p.theme.media.smallOnly(css`
					padding: ${p =>
						`0 0 1px ${p.theme.spacing.tablet.medium}`} !important;
				`)}
			li {
				margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
				&::before {
					font-family: 'Font Awesome 6 Pro';
					font-weight: 900;
					content: '\\f0da';
					display: none;
				}

				svg {
					color: ${p => p.theme.colors.black};
				}

				// Child ul inside parent li
				ul {
					margin: ${p =>
						`${p.theme.spacing.desktop.xxsmall} 0 ${p.theme.spacing.desktop.xsmall} 0`};
					display: block;
					width: 100%;
					li {
						margin-bottom: ${p => p.theme.spacing.mobile.xxsmall};
						&::before {
							font-family: 'Font Awesome 6 Pro';
							font-weight: 300;
							content: '\\f0da';
							display: none;
						}
						svg {
							top: 7px !important;
						}
					}
				}
			}
		}

		// Numbered lists
		ol {
			list-style: none;
			counter-reset: li;
			li {
				counter-increment: li;
				padding-left: 0;
				margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
				> h1,
				> h2,
				> h3,
				> h4,
				> h5,
				> h6,
				> p {
					width: 100%;
				}
				&::before {
					content: counter(li);
					color: ${p => p.theme.colors.black};
					background-color: ${p => p.theme.colors.blue300};
					font-weight: 400;
					width: 25px;
					height: 25px;
					border-radius: 25px;
					left: -${p => p.theme.spacing.tablet.medium};
					top: 3px;
					position: absolute;
					text-align: center;
					font-size: 17px;
					line-height: 25px;
				}
			}
		}
	`;
}

export function ListWithIconsStyling() {
	// Add fontAwesome icon to library and watch DOM
	library.add(faCheck, faXmark);
	dom.watch();

	return css`
		${p =>
			(p.$checkicon === 'true' || p.$xmarkicon === 'true') &&
			css`
				ol,
				ul {
					padding: ${p =>
						`0 0 ${p.theme.spacing.desktop.xsmall} ${p.theme.spacing.desktop.small}`} !important;
					${p =>
						p.theme.media.mediumDown(css`
							padding: ${p =>
								`0 0 ${p.theme.spacing.desktop.xsmall} 25px`} !important;
						`)};
					li {
						margin-bottom: ${p =>
							p.theme.spacing.desktop.xxsmall} !important;
						padding-left: 0;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						text-align: left;
						line-height: 29px;
						&:before {
							background-image: none !important;
						}

						> p > b {
							display: block;
							font-size: 22px;
							line-height: 32px;
							margin-bottom: ${p =>
								p.theme.spacing.desktop.xxsmall} !important;
							${p =>
								p.theme.media.mediumDown(css`
									font-size: 20px;
									line-height: 30px;
								`)};
						}
					}
				}
			`};

		${p =>
			p.$checkicon === 'true' &&
			css`
				ol,
				ul {
					padding: ${p =>
						`0 0 0 ${p.theme.spacing.tablet.medium}`} !important;
					li {
						padding-left: 0 !important;
						&:before {
							font-family: 'Font Awesome 6 Pro' !important;
							font-weight: 900 !important;
							content: '\\f00c' !important;
							display: none;
						}
						svg[data-icon='check'] {
							position: absolute;
							width: 20px !important;
							height: 29px !important;
							left: -40px !important;
							top: 0 !important;
							color: ${p =>
								['grey800', 'blue800'].includes(p.bg)
									? p.theme.colors.white
									: p.theme.colors.green600} !important;
						}
					}
				}
			`};

		${p =>
			p.$xmarkicon === 'true' &&
			css`
				ol {
					padding: ${p =>
						`0 0 0 ${p.theme.spacing.tablet.medium}`} !important;
					li {
						&:before {
							font-family: 'Font Awesome 6 Pro' !important;
							font-weight: 900 !important;
							content: '\\f00d' !important;
							display: none;
						}
						svg[data-icon='xmark'] {
							position: absolute;
							width: 20px !important;
							height: 29px !important;
							left: -40px !important;
							top: 0 !important;
							color: ${p =>
								['grey800', 'blue800'].includes(p.bg)
									? p.theme.colors.white
									: p.theme.colors.coral600} !important;
						}
					}
				}
			`};

		${p =>
			p.theme.media.large(css`
				${p =>
					p.$checkicon === 'true' &&
					['grey800', 'blue800'].includes(p.bg) &&
					css`
						ul {
							columns: 2;
							-webkit-columns: 2;
							-moz-columns: 2;
						}
					`};
			`)};
	`;
}

export function StickyTableStyling() {
	return css`
		table {
			thead {
				position: relative;
				tr {
					td:first-of-type {
						position: sticky;
						left: 0;
						z-index: 3 !important;
						border-radius: ${p => p.theme.utils.borderRadius} 0 0 0 !important;
					}
				}
			}
			thead tr:first-of-type td,
			tbody > tr td:first-of-type {
				position: sticky;
				left: 0;
				color: white;
				z-index: 2;
				background-color: ${p => p.theme.colors.blue600};
				p {
					font-weight: 500;
					color: white !important;
				}
			}
			tbody > tr:nth-child(odd) td:first-of-type {
				background-color: ${p => darken(0.03, p.theme.colors.blue600)};
			}
			tbody > tr td:first-of-type {
				border-bottom: 1px solid
					${p => darken(0.06, p.theme.colors.blue600)};
				border-left: 1px solid
					${p => darken(0.06, p.theme.colors.blue600)};
			}
		}
	`;
}

export function TitleWithBadgeStyling() {
	return css`
		position: relative;
		max-width: max-content;
		padding-right: ${p => p.theme.spacing.tablet.medium} !important;
		${p =>
			p.theme.media.XSmallOnly(css`
				padding-right: ${p => p.theme.spacing.tablet.small} !important;
			`)};
		.badge__news {
			left: auto;
			top: -40px;
			right: -40px !important;
			${p =>
				p.theme.media.mediumDown(css`
					top: -40px;
					right: -40px !important;
					left: auto !important;
				`)};
			${p =>
				p.theme.media.smallOnly(css`
					right: -20px !important;
					top: -20px;
				`)};
		}
		> div {
			width: max-content;
			margin: ${p => `0 0 ${p.theme.spacing.desktop.xxsmall}`};
		}

		svg {
			width: 60px !important;
			height: 60px !important;
			${p =>
				p.theme.media.smallOnly(css`
					width: 50px !important;
					height: 50px !important;
				`)};
		}
	`;
}

export function BreadTextStyle() {
	return css`
		${ListStyling}
		${p =>
			p.theme.media.smallOnly(css`
				padding: 0;
			`)}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: ${p => p.theme.spacing.tablet.large};
			margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
			${p =>
				p.theme.media.smallOnly(css`
					margin-top: ${p => p.theme.spacing.desktop.medium};
				`)}
		}
		p {
			white-space: pre-wrap;
		}
		img {
			border-radius: ${p => p.theme.utils.borderRadius};
			${p =>
				p.theme.media.smallOnly(css`
					border-radius: 0;
				`)}
		}
		.component__image--img {
			${p =>
				p.theme.media.medium(css`
					max-width: 100vw;
					width: 100vw;
					position: relative;
					left: 50%;
					right: 50%;
					margin-left: -50vw;
					margin-right: -50vw;
				`)}
		}
	`;
}

export function ImageWrapperStyle() {
	return css`
		${p =>
			p.theme.media.medium(css`
				max-width: 100vw;
				width: 100vw;
				position: relative;
				left: 50%;
				right: 50%;
				margin-left: -50vw;
				margin-right: -50vw;
			`)}
		img {
			border-radius: ${p => p.theme.utils.borderRadius};
		}

		> figure {
			text-align: center;
			max-width: ${p => p.theme.widths.large};
		}
	`;
}

/**
 * Generates margin bottom for a list of items.
 * @param {number} perrow - Number of items per row.
 * @returns {string} - A string representing the margin bottom for the list of items.
 */
export const generateMarginBottom = perrow => css`
	&:nth-child(${perrow}n+1):nth-last-child(-n + ${perrow}),
	&:nth-child(${perrow}n+1):nth-last-child(-n + ${perrow}) ~ div {
		${p =>
			p.theme.media.large(css`
				margin-bottom: 0 !important;
			`)}
	}

	// When per row is not 1 then we need to add a special case for medium screens
	${p =>
		p.theme.media.mediumDown(css`
			${(perrow !== 1 &&
				css`
					&:nth-child(2n + 1):nth-last-child(-n + 2),
					&:nth-child(2n + 1):nth-last-child(-n + 2) ~ div {
						margin-bottom: 0 !important;
					}
				`) ||
			css`
				&:nth-child(${perrow}n+1):nth-last-child(-n + ${perrow}),
				&:nth-child(${perrow}n+1):nth-last-child(-n + ${perrow}) ~ div {
					margin-bottom: 0 !important;
				}
			`};
		`)}
`;

/**
 * Style for the first list item of a list
 * Style for the first element
 * @param {string} el - The HTML element
 * @returns {string} - The CSS
 */
export function generateFirstOfTypeStyle(el) {
	// If the element is a h3
	if (el === 'h3') {
		return css`
			${el}:first-of-type {
				margin-top: 0;
				margin-bottom: 10px;
			}
		`;
	}

	// If the element is not a list, return
	if (el !== 'ul' || el !== 'ol') {
		return;
	}

	return css`
		${el}:first-of-type {
			margin-top: 30px;
		}
	`;
}

/**
 * Style for the last element
 * @param {string} el - The HTML element
 * @returns {string} - The CSS
 */
export function generateLastOfTypeStyle(el) {
	return css`
		${el}:last-of-type {
			margin-bottom: 0;
		}
	`;
}

/**
 * Define a function to generate CSS for nth-child and nth-last-child
 * @param {number} max - The maximum number of columns
 * @returns {string} - The generated CSS string
 */
export function generateNthChildCSS(max = 3) {
	return css`
		&:nth-child(${max}n + 1):nth-last-child(-n + ${max}),
		&:nth-child(${max}n + 1):nth-last-child(-n + ${max}) ~ div {
			${p =>
				p.theme.media.medium(css`
					padding-bottom: 0;
				`)}
		}
	`;
}
